@import "normalize.css";
@import "./variables.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list {
  list-style: disc;
  margin-left: 25px;
}

.list li {
  margin: 5px 0;
}

body {
  overflow: hidden;
  width: 100% !important; /* This is necessary because when the antDesign drawer is opened, it changes the body style and directly sets the width */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  min-width: 100%;
  padding: 0 15px;
}

div.ant-collapse-content > .ant-collapse-content-box {
  background: var(--grey-800);
  border: none;
  padding: 0 15px;
}

.ant-collapse-item:last-child > .ant-collapse-content {
  border: none;
}

span.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

span.ant-checkbox-inner {
  background-color: rgba(0, 0, 0, 0);
}

div.ant-popover-inner {
  background-color: var(--grey-600);
  border-radius: 5px;
}

div.ant-popover-arrow {
  display: none;
}

.ant-btn-icon-only {
  flex: none;
  padding: 10px;
}

div.ant-select-dropdown {
  background: var(--grey-600);
  border-radius: 5px;
}

div.ant-select-item {
  padding: 0;
}

.ant-select-item-option {
  background: var(--grey-600);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.ant-select-item-option:last-child {
  border-bottom: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
  background-color: var(--grey-700);
}

.ant-select-focused {
  background: var(--grey-600);
  border-radius: 5px;
}

.side-bar__close {
  top: 100px !important;
}

.anticon svg {
  fill: var(--white);
}

.anticon-caret-down svg {
  vertical-align: middle;
}

div.ant-picker-range {
  background: none;
  border-radius: 5px;
  border: none;
  width: 100%;
}

div.ant-picker-range:hover {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.ant-picker-input > input {
  color: var(--white) !important;
}

.ant-modal-close {
  display: none;
}

span.ant-picker-clear {
  background: var(--grey-800);
}

div.ant-modal-content {
  box-shadow: none;
  background: none;
  width: inherit;
  display: flex;
  justify-content: center;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.ant-modal-body {
  padding: 0;
  width: inherit;
}

.ant-modal-header {
  display: none;
}

.map-locale-controls {
  display: none;
}

div.toggle-3d {
  display: none;
}

div.draw-feature.toolbar-item {
  display: none;
}

.map-draw-controls {
  display: none;
}

/*
  Nested here are used to hide unnecessary kepler functions for working with feature polygons that might break the logic.
  Why nested because i didn't find other way.
 */
.feature-action-panel > div > div:nth-child(1),
.feature-action-panel > div > div:nth-child(2) {
  display: none;
}

div.kepler-gl {
  z-index: 2;
  position: absolute;
  right: 0;
}

.kepler-gl .bottom-widget--inner {
  background: #b0b4ba;
}

.kepler-gl .bottom-widget__field {
  color: #111827;
}

.kepler-gl .bottom-widget__field span {
  color: #111827;
  line-height: 15px;
  font-size: 12px;
  font-weight: 700;
}

div.kepler-gl .timeline-container {
  width: 100% !important;
}

div.timeline-container {
  padding-top: 30px; /* needed to fix removal of fmixVB */
}

div.home_aboutPopupHeaderTitle__1YLYA {
  width: 100%;
}

/* this _should_ allow arabic to align naturally RTL */
div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li {
  text-align: start;
  unicode-bidi: plaintext;
}

ul {
  list-style-type: none !important;
}

.keplergl_aboutPopupTitle__3CHWM,
.keplergl_aboutPopupTitleSub__30kd0,
.keplergl_legendPopupDBName__2pAR7 {
  display: block;
  text-align: center;
}

div.kepler-gl g.tick text {
  fill: #252b39;
  font-size: 10px;
  font-weight: 500;
}

.kepler-gl .bottom-widget--inner .playback-controls {
  position: relative;
  right: 50%;
  transform: translateX(50%);
}

.kepler-gl .time-range-slider__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.kepler-gl .bottom-widget--inner .selection {
  stroke: none;
  fill: #dadfe7;
}

.kepler-gl .bottom-widget--inner path.handle--custom {
  fill: #059669;
}

.kepler-gl .time-range-slider .histogram-bars rect {
  fill: #6b7280;
  rx: 2px;
  ry: 2px;
}

.kepler-gl .time-range-slider .histogram-bars rect.in-range {
  fill: #00112a;
}

.kepler-gl .floating-time-display {
  display: none;
}

.kepler-gl .map-control-button {
  background: #4c5564;
  color: white;
  border-radius: 5px;
}

div.map-control {
  top: 45px;
}

div.map-popover {
  overflow: hidden;
  box-shadow: none;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0);
}

div.map-popover:hover {
  background-color: rgba(0, 0, 0, 0);
}

.map-popover > div {
  opacity: 0;
}
/*
  !important need to shadow kepler-gl default styles
*/
div.bottom-widget__y-axis {
  display: none;
}

/*
  Its the name of the class that is the main container of the timeline block
*/
.jYDrHE {
  width: 100%;
}

div.bottom-widget--container {
  padding: 0;
  transition: all 0.3s;
  width: 100%;
}

.ant-modal {
  width: 94% !important;
  display: flex;
  justify-content: center;
}

.show-legend {
  display: none !important;
}

.rsme-embed > div {
  display: flex;
  justify-content: center;
}

div.notification-item {
  display: none;
}

span.ant-checkbox-checked:after {
  border-color: var(--primary-color);
}

div.time-widget--top .sc-jSUZER:nth-child(3) {
  /* its class for default kepler timeline open | close button */
  display: none;
}

div.sc-fmixVB {
  /* class for kepler "Timeline of Veerified data" span */
  display: none;
}

/*
  fix widget tooltip
*/
.kepler-gl .bottom-widget--inner .playback-controls .place-top::after {
  background-color: inherit;
}

.kepler-gl .bottom-widget--inner .playback-controls .place-top::before {
  display: none;
}

div.kg-range-slider__slider {
  display: none;
}

@media (min-width: 768px) {
  .kepler-gl .bottom-widget--inner .playback-controls {
    position: absolute;
    top: 15px;
    right: 50%;
    transform: translateX(50%);
    text-transform: capitalize;
  }

  div.map-control {
    top: unset;
  }

  div.kg-range-slider__slider {
    display: unset;
  }

  .ant-modal {
    width: 80% !important;
  }

  div.ant-picker-range {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  span.ant-picker-clear {
    background: var(--grey-700);
  }
}
