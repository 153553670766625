.mapChanger {
  color: var(--white);
  padding: 10px 15px;
}

.mapHub {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mapHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.maps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

div.openMoreMapsCardClose {
  display: none;
}

.mapCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 40px;
  height: 64px;
  margin: 7px;
  font-size: 12px;
  cursor: pointer;
}

.mapCardImg {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

.mapHubLegacyOpen {
  max-height: 178px;
}

.mapHubLegacyClose {
  max-height: 0;
}

.mapHubLegacy {
  transition: all 0.3s;
  overflow: hidden;
}

.mapDividerClose {
  display: none;
}

.mapDividerOpen {
  display: block;
}

.mapDivider {
  width: 224px;
  height: 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 10px 0;
}

.mapControlsButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mapControlsButtons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

button.mapControlButton:hover {
  border-color: var(--default-grey);
  background: var(--default-grey);
}

.closeMapsBtn {
  cursor: pointer;
}

.openMapsButton {
  width: 40px;
  height: 40px;
  border: 1px solid var(--white);
  filter: drop-shadow(1px 1px 11px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
  cursor: pointer;
}

.openMapsButton img {
  width: 100%;
  height: 100%;
}

.timelineControlButton {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 40px;
  height: 20px;
  border-radius: 5px 5px 0 0;
  background: var(--timeline-background);
  border: none;
  cursor: pointer;
  z-index: -1;
}

.timelineControlImage {
  width: 10px;
  transition: all 0.3s;
}

.timelineControlImageClose {
  transform: rotateX(180deg);
}

.timelineBlock {
  transform: translateY(0);
}

.timelineBlockClose {
  transform: translateY(calc(100% - 20px));
}

.chosenMap {
  border: var(--chosen-map-style-border);
  border-radius: 5px;
}

.mapControllerWrapper {
  position: fixed;
  bottom: 35px;
  right: 10px;
  z-index: 2;
  transition: all 0.3s;
}

div.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 1;
  margin: 0;
  word-break: break-word;
}

div.keplerTooltipContainer {
  display: block;
  width: 100%;
  position: unset;
  left: unset;
  transform: unset;
  height: fit-content;
  padding: 10px 15px;
  background: var(--grey-800);
  border-radius: 5px;
}

div.tooltipInfoContainer {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

div.tooltipHeader {
  position: relative;
  display: flex;
  gap: 10px;
  margin: 5px 0;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

div.tooltipGraphicContentLevel {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 20px;
}

time.tooltipTime {
  flex: none;
  position: relative;
  right: unset;
}

.editorPopup {
  border-radius: 10px;
}

.editorItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: var(--white);
  cursor: pointer;
}

.editorItem:hover {
  opacity: 0.8;
}

.selectedEditorItem {
  background: var(--grey-500);
}

.keplerMapButtons {
  position: absolute;
  right: 12px;
  top: 58px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: end;
}

button.keplerEditorModeBtn {
  display: none;
}

.keplerMapButton {
  padding: 7px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  background: #4c5564;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: none;
}

.expandContainer {
  height: 100%;
  border-radius: 5px;
  background: #4c5564;
  transition: all 0.3s;
}

.expandPopupContent {
  position: relative;
  padding: 10px;
  color: white;
  background: var(--grey-600);
  border-radius: 10px;
  max-width: 350px;
}

.databaseContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.legendPopupDBName {
  font-size: 16px;
  font-weight: 500;
}

.legendPopupDBCategory {
  display: flex;
  align-items: center;
  gap: 5px;
}

.DBCategoryName {
  color: var(--weak-white);
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.DBCategoryColor {
  width: 20px;
  height: 10px;
  flex: none;
}

.popUpCategoriesList {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.aboutPopupTitle {
  color: var(--white);
  margin: 0;
  font-size: 18px;
}

.aboutPopupTitleSub {
  margin: 0;
}

.minimizeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--light-gray);
  cursor: pointer;
}

.hideExpandBtn {
  display: none;
}

.expandBtn {
  padding: 7px;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .keplerMapButtons {
    top: 12px;
  }

  button.keplerEditorModeBtn {
    display: flex;
    text-transform: capitalize;
  }
}
